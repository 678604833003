import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import Footer from "../components/Footer";

const Lookup = (props) => {
  const [tokenId, setTokenId] = useState(null);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length < 5 && value[0] != 0) {
      setTokenId(value);
    }
  };

  const isReadyToBreed = () => {
    if (tokenId < 1 || tokenId > 9999) {
      props.setAlertState({
        open: true,
        message: "Please enter a value between 1 and 9999",
        severity: "error",
      });
    } else if (props.babiesContract) {
      props.babiesContract.methods
        .apeReadyToBreed("0xF3114DD5c5b50a573E66596563D15A630ED359b4", tokenId)
        .call()
        .then((isReady) => {
          if (isReady == true) {
            props.setAlertState({
              open: true,
              message: `Fat Ape #${tokenId} is ready to breed!`,
              severity: "success",
            });
          } else {
            props.babiesContract.methods
              .apeNextBreedingDate(
                "0xF3114DD5c5b50a573E66596563D15A630ED359b4",
                tokenId
              )
              .call()
              .then((date) => {
                props.setAlertState({
                  open: true,
                  message: `Fat Ape #${tokenId} will be ready to breed on ${new Date(
                    date * 1000
                  ).toDateString()}`,
                  severity: "error",
                });
              });
          }
        });
    } else if (!props.babiesContract) {
      props.setAlertState({
        open: true,
        message: `Looks like metamask is not installed`,
        severity: "error",
      });
    }
  };

  return (
    <>
      <Container className="verify-container">
        <div>
          <h2 style={{ textAlign: "center" }}>Lookup</h2>
          <div className="breeding-subtext">
            {/* <p>
              Once the mating is done, you will automatically see your
              unrevealed Baby Fat Ape appear in your wallet, but it will take{" "}
              <b>4 weeks</b> for the babies to reveal. There is also a{" "}
              <b>8 weeks cooldown</b> for the parents, which means that the Fat
              Apes can only breed once every 8 weeks.
            </p> */}

            <p>
              <b>
                Before buying any ape to breed, you should always lookup if he's
                ready to breed.
              </b>
            </p>
            <p>
              Breeding rare Fat Apes increases your odds of getting a{" "}
              <b>rare baby</b> as well.
            </p>
            <p>
              <b>Reveal:</b> 4 weeks
            </p>
            <p>
              <b>Cooldown:</b> 8 weeks
            </p>
          </div>
          <div className="lookup-input-container">
            <TextField
              id="outlined-basic"
              label="Fat Ape ID"
              variant="outlined"
              className="lookup-input"
              //   inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={tokenId}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div onClick={() => isReadyToBreed()} className="top40">
            <a className="connect-button border-btn ">Lookup an ape</a>
          </div>
        </div>
        <Snackbar
          open={props.alertState.open}
          autoHideDuration={6000}
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          className="alert"
        >
          <Alert
            onClose={() =>
              props.setAlertState({ ...props.alertState, open: false })
            }
            severity={props.alertState.severity}
            className="alert"
          >
            <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
          </Alert>
        </Snackbar>
      </Container>
      <Footer />
    </>
  );
};

export default Lookup;
