import "./App.css";
import React, { useEffect, useState } from "react";

import Home from "./Home";
import Navbar from "./components/Navbar";
import Terms from "./pages/terms";
import { createTheme, ThemeProvider } from "@material-ui/core";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Web3 from "web3";
import FatApeContract from "./abis/FatApeClub.json";
import FatApeBabiesContract from "./abis/FatApeBabiesClub.json";
import FatBananas from "./abis/FatBananas.json";
import LadiesClub from "./abis/FatApeLadiesClub.json";

import Aos from "aos";
import Loader from "./components/Loader";
import { fireConfetti } from "./components/confetti";
import Whitelisted from "./pages/whitelisted";
import HowToMint from "./pages/howToMint";
import Breeding from "./pages/breeding";
import Profile from "./pages/profile";
import Lookup from "./pages/lookup";
import LookupFAB from "./pages/lookupFAB";
import Ladies from "./pages/ladies";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#000000",
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const App = () => {
  const [contract, setContract] = useState(null);
  const [babiesContract, setBabiesContract] = useState(null);
  const [fatBananasContract, setFatBananasContract] = useState(null);
  const [ladiesContract, setLadiesContract] = useState(null);

  const [account, setAccount] = useState(null);
  const [metamask, setMetamask] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [startDate, setStartDate] = useState(new Date(1635996420 * 1000));

  const [isPaused, setIsPaused] = useState(null);
  const [isSoldOut, setIsSoldOut] = useState(null);
  const [isWhitelisted, setIsWhitelisted] = useState(null);
  const [exceedLimit, setExceedLimit] = useState(null);
  const [isPresale, setIsPresale] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [nftLimit, setnftLimit] = useState(null);
  const [numberOfToken, setNumberOfToken] = useState(null);
  const [currentMaxSupply, setCurrentMaxSupply] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [preSaleDate, setPreSaleDate] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [count, setCount] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [cost, setCost] = useState(null);
  const [fatBananasCost, setFaBananasCost] = useState(null);
  const [isFatBananasMinting, setFatBananasIsMinting] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
    loadWeb3();
  }, []);

  useEffect(() => {
    if (contract != null && contract.methods != null && account != null) {
      getInfos();
    }
  }, [contract]);

  useEffect(() => {
    if (
      ladiesContract != null &&
      ladiesContract.methods != null &&
      account != null
    ) {
      getLadiesInfos();
    }
  }, [contract]);

  const getInfos = () => {
    // contract.methods
    //   .preSaleDate()
    //   .call()
    //   .then((presaleDate) => {
    //     setPreSaleDate(new Date(presaleDate * 1000));
    //     contract.methods
    //       .publicSaleDate()
    //       .call()
    //       .then((publicSaleDate) => {
    //         const now = Date.now();
    //         if (
    //           now > new Date(presaleDate * 1000) &&
    //           now < new Date(publicSaleDate * 1000)
    //         ) {
    //           setIsPresale(true);
    //           contract.methods
    //             .nftPerAddressLimitPresale()
    //             .call()
    //             .then((limit) => {
    //               setnftLimit(parseInt(limit));
    //               contract.methods
    //                 .walletOfOwner(account)
    //                 .call()
    //                 .then((tokens) => {
    //                   if (tokens.length >= limit) {
    //                     setExceedLimit(true);
    //                   }
    //                 });
    //             });
    //           contract.methods
    //             .preSaleMaxSupply()
    //             .call()
    //             .then((maxSupply) => {
    //               setCurrentMaxSupply(parseInt(maxSupply));
    //             });
    //         } else if (now > publicSaleDate * 1000) {
    //           setIsPublic(true);
    //           setIsPresale(false);
    //           contract.methods
    //             .nftPerAddressLimit()
    //             .call()
    //             .then((limit) => {
    //               setnftLimit(parseInt(limit));
    //               contract.methods
    //                 .walletOfOwner(account)
    //                 .call()
    //                 .then((tokens) => {
    //                   setNumberOfToken(tokens.length);
    //                   if (tokens.length >= limit) {
    //                     setExceedLimit(true);
    //                   } else {
    //                     setExceedLimit(false);
    //                   }
    //                 });
    //             });
    //           contract.methods
    //             .maxSupply()
    //             .call()
    //             .then((maxSupply) => {
    //               setCurrentMaxSupply(parseInt(maxSupply));
    //             });
    //         } else {
    //           setnftLimit(0);
    //         }
    //       });
    //   });
    // contract.methods
    //   .paused()
    //   .call()
    //   .then((response) => {
    //     setIsPaused(response);
    //   });
    // contract.methods
    //   .isWhitelisted(account)
    //   .call()
    //   .then((response) => {
    //     setIsWhitelisted(response);
    //   });
    // contract.methods
    //   .maxSupply()
    //   .call()
    //   .then((maxSupply) => {
    //     // setTotalSold(totalSold);
    //     contract.methods
    //       .totalSupply()
    //       .call()
    //       .then((response) => {
    //         setTotalSupply(parseInt(response));
    //         if (parseInt(response) >= parseInt(maxSupply)) {
    //           setIsSoldOut(true);
    //         }
    //       });
    //   });
  };

  const getLadiesInfos = () => {
    // getListOfHolders();

    ladiesContract.methods
      .publicSaleDate()
      .call()
      .then((publicSaleDate) => {
        const now = Date.now();
        if (now > publicSaleDate * 1000) {
          setIsPublic(true);

          // ladiesContract.methods
          //   .nftPerAddressLimit()
          //   .call()
          //   .then((limit) => {
          //     setnftLimit(parseInt(limit));
          //     ladiesContract.methods
          //       .walletOfOwner(account)
          //       .call()
          //       .then((tokens) => {
          //         setNumberOfToken(tokens.length);

          //         if (tokens.length >= limit) {
          //           setExceedLimit(true);
          //         } else {
          //           setExceedLimit(false);
          //         }
          //       });
          //   });

          ladiesContract.methods
            .maxSupply()
            .call()
            .then((maxSupply) => {
              setCurrentMaxSupply(parseInt(maxSupply));
            });
        } else {
          setnftLimit(0);
        }
      });

    ladiesContract.methods
      .paused()
      .call()
      .then((response) => {
        setIsPaused(response);
      });

    ladiesContract.methods
      .maxSupply()
      .call()
      .then((maxSupply) => {
        // setTotalSold(totalSold);
        ladiesContract.methods
          .totalSupply()
          .call()
          .then((response) => {
            setTotalSupply(parseInt(response));
            if (parseInt(response) >= parseInt(maxSupply)) {
              setIsSoldOut(true);
            }
          });
      });
  };

  const getCost = async () => {
    const cost_per_mint = await contract.methods.getCurrentCost().call();
    return cost_per_mint;
  };

  const getLadiesCost = async () => {
    const cost_per_mint = await ladiesContract.methods.cost().call();
    return cost_per_mint;
  };

  const getLadiesBananasCost = async () => {
    const cost_per_mint = await ladiesContract.methods.fatBananasCost().call();
    return cost_per_mint;
  };

  const loadWeb3 = async () => {
    if (window.ethereum) {
      //ALL GOOD
      window.web3 = new Web3(window.ethereum);
      setMetamask(true);

      window.web3.eth.getAccounts().then(async (addr) => {
        // Set User account into state
        if (addr.length > 0) {
          setIsWalletConnected(true);
        } else {
          setIsWalletConnected(false);
        }
      });
      loadBlockchainData();
    } else if (window.web3) {
      //display CONNECT WALLET
      window.web3 = new Web3(window.web3.currentProvider);

      setMetamask(true);
      // setIsWalletConnected(false);
      loadBlockchainData();
    } else {
      //METAMASK NOT INSTALLED
      setMetamask(false);
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    // Load account
    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);

    const networkId = await web3.eth.net.getId();
    const networkData = FatApeContract.networks[networkId];
    const networkDataBabies = FatApeBabiesContract.networks[networkId];
    const networkDataFatBananas = FatBananas.networks[networkId];
    const networkDataLadies = LadiesClub.networks[networkId];

    if (networkData) {
      const abi = FatApeContract.abi;
      const address = networkData.address;
      const contract = new web3.eth.Contract(abi, address);
      setContract(contract);
      const BabiesAbi = FatApeBabiesContract.abi;
      const BabiesAddress = networkDataBabies.address;
      const BabiesContract = new web3.eth.Contract(BabiesAbi, BabiesAddress, {
        gas: 500000,
      });
      setBabiesContract(BabiesContract);

      const FatBananasAbi = FatBananas.abi;
      const FatBananasaddress = networkDataFatBananas.address;
      const FatBananasContract = new web3.eth.Contract(
        FatBananasAbi,
        FatBananasaddress
      );
      setFatBananasContract(FatBananasContract);

      const LadiesAbi = LadiesClub.abi;
      const LadiesAddress = networkDataLadies.address;
      const LadiesContract = new web3.eth.Contract(LadiesAbi, LadiesAddress);
      setLadiesContract(LadiesContract);
    } else {
      // window.alert("Smart contract not deployed to detected network.");
    }
  };

  if (window.web3) {
    window.ethereum.on("accountsChanged", function () {
      window.web3.eth.getAccounts().then((accounts) => {
        window.location.reload();

        // setAccount(accounts[0]);
        // setIsWalletConnected(true);
        // // loadWeb3();
        // getInfos();
      });
    });

    window.ethereum.on("networkChanged", function (networkId) {
      window.location.reload();
    });
  }

  // const mintApe = async () => {
  //   setAlertState({
  //     open: true,
  //     message: "Sale has not started yet",
  //     severity: "error",
  //   });
  // };

  const mintApe = async () => {
    await loadWeb3();

    // setIsSoldOut(true);
    // await getInfos();
    if (isPaused) {
      setAlertState({
        open: true,
        message: "The minting is on pause",
        severity: "error",
      });
    } else if (isPresale == false && isPublic == false) {
      setAlertState({
        open: true,
        message: "Presale has not started yet",
        severity: "error",
      });
    } else if (isPublic && count > 10) {
      setAlertState({
        open: true,
        message: "Can't mint more than 10 apes per transaction",
        severity: "error",
      });
    } else if (isPresale && count > 1) {
      setAlertState({
        open: true,
        message: "Can't mint more than 1 ape in presale",
        severity: "error",
      });
    } else if (isPresale && !isWhitelisted && !isPublic) {
      setAlertState({
        open: true,
        message: "You're not whitelisted, come back for the public sale",
        severity: "error",
      });
    } else if (exceedLimit) {
      setAlertState({
        open: true,
        message: "You've reached the limit of apes per wallet",
        severity: "error",
      });
    } else if (numberOfToken != null && numberOfToken + count > nftLimit) {
      setAlertState({
        open: true,
        message: "You've reached the limit of apes per wallet",
        severity: "error",
      });
    } else if (isSoldOut) {
      setAlertState({
        open: true,
        message: "The collection is sold out",
        severity: "error",
      });
    } else if (totalSupply + count > currentMaxSupply) {
      let msg = "";
      if (isPublic) {
        msg = "Not enough Apes left";
      } else {
        msg = "Presale supply sold out ";
      }
      setAlertState({
        open: true,
        message: msg,
        severity: "error",
      });
    } else {
      setIsMinting(true);

      const cost_per_mint = await getCost();
      const cost = count * cost_per_mint;

      ladiesContract.methods
        .mint(count)
        .send({ from: account, value: cost.toString() })
        .once("receipt", (receipt) => {
          setAlertState({
            open: true,
            message: "Congrat's! You're now a Fat Ape",
            severity: "success",
          });
          fireConfetti();
          getInfos();
          setIsMinting(false);
        })
        .catch((e) => {
          setIsMinting(false);
        });
    }
  };

  const mintLady = async () => {
    await loadWeb3();

    if (isPaused) {
      setAlertState({
        open: true,
        message: "The minting is on pause",
        severity: "error",
      });
    } else if (isPublic && count > 10) {
      setAlertState({
        open: true,
        message: "Can't mint more than 10 ladies per transaction",
        severity: "error",
      });
    } else if (isSoldOut) {
      setAlertState({
        open: true,
        message: "The collection is sold out",
        severity: "error",
      });
    }
    // else if (totalSupply + count > currentMaxSupply) {
    //   setAlertState({
    //     open: true,
    //     message: "Not enough Ladies left",
    //     severity: "error",
    //   });
    // }
    else {
      setIsMinting(true);

      const cost_per_mint = await getLadiesCost();
      const cost = count * cost_per_mint;

      ladiesContract.methods
        .mint(count)
        .send({ from: account, value: cost.toString() })
        .once("receipt", (receipt) => {
          setAlertState({
            open: true,
            message: "Congrat's! You're now a Fat Lady",
            severity: "success",
          });
          fireConfetti();
          getLadiesInfos();
          setIsMinting(false);
        })
        .catch((e) => {
          setIsMinting(false);
        });
    }
  };

  useEffect(() => {
    if (account && ladiesContract) {
      ladiesContract.methods
        .cost()
        .call()
        .then((cost) => {
          setCost(cost / 1000000000000000000);
        });

      ladiesContract.methods
        .fatBananasCost()
        .call()
        .then((cost) => {
          setFaBananasCost(cost / 1000000000000000000);
        });
    }
  }, [ladiesContract]);

  const buyWithFatBananas = async () => {
    await loadWeb3();

    const cost_per_mint = await getLadiesBananasCost();
    const total_cost = count * cost_per_mint;

    const balance = await fatBananasContract.methods.balanceOf(account).call();

    if (isPaused) {
      setAlertState({
        open: true,
        message: "The minting is on pause",
        severity: "error",
      });
    } else if (isPublic && count > 10) {
      setAlertState({
        open: true,
        message: "Can't mint more than 10 ladies per transaction",
        severity: "error",
      });
    } else if (isSoldOut) {
      setAlertState({
        open: true,
        message: "The collection is sold out",
        severity: "error",
      });
    }
    // else if (totalSupply + count > currentMaxSupply) {
    //   setAlertState({
    //     open: true,
    //     message: "Not enough Ladies left",
    //     severity: "error",
    //   });
    // }
    else if (parseInt(total_cost) > parseInt(balance)) {
      setAlertState({
        open: true,
        message: "Not enough Fat Bananas",
        severity: "error",
      });
    } else {
      setFatBananasIsMinting(true);

      ladiesContract.methods
        .mintWithFatBananas(count)
        .send({ from: account, value: "" })
        .then(() => {
          setAlertState({
            open: true,
            message: "Congrat's! You're now a Fat Lady",
            severity: "success",
          });
          fireConfetti();
          getLadiesInfos();
          setFatBananasIsMinting(false);
        })
        // .send({ from: account, value: "0" })
        // .once("receipt", (receipt) => {
        //   setAlertState({
        //     open: true,
        //     message: "Congrat's! You're now a Fat Lady",
        //     severity: "success",
        //   });
        //   fireConfetti();
        //   getLadiesInfos();
        //   setFatBananasIsMinting(false);
        // })
        .catch((e) => {
          setFatBananasIsMinting(false);
        });
    }
  };

  // function getListOfHolders() {
  //   let holders = [];

  //   contract.methods
  //     .totalSupply()
  //     .call()
  //     .then((totalSupply) => {
  //       for (let i = 1; i <= totalSupply; i++) {
  //         contract.methods
  //           .ownerOf(i)
  //           .call()
  //           .then((address) => {
  //             holders.push(address);
  //             console.log(holders);
  //           });
  //       }
  //     });
  // }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Loader />
      <Navbar />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home
              metamask={metamask}
              isWalletConnected={isWalletConnected}
              mintApe={mintApe}
              // startDate={startDate}
              startDate={startDate}
              isActive={isActive}
              setIsActive={setIsActive}
              setCount={setCount}
              count={count}
              isPaused={isPaused}
              isSoldOut={isSoldOut}
              isWhitelisted={isWhitelisted}
              exceedLimit={exceedLimit}
              isPresale={isPresale}
              isPublic={isPublic}
              alertState={alertState}
              setAlertState={setAlertState}
              isMinting={isMinting}
            />
          </Route>
          <Route exact path="/whitelisted">
            <Whitelisted
              metamask={metamask}
              isWalletConnected={isWalletConnected}
              isWhitelisted={isWhitelisted}
              alertState={alertState}
              setAlertState={setAlertState}
              account={account}
            />
          </Route>
          {/* <Route exact path="/how-to-mint">
            <HowToMint />
          </Route> */}
          <Route exact path="/breeding">
            <Breeding
              loadWeb3={loadWeb3}
              contract={contract}
              babiesContract={babiesContract}
              metamask={metamask}
              isWalletConnected={isWalletConnected}
              isWhitelisted={isWhitelisted}
              alertState={alertState}
              setAlertState={setAlertState}
              account={account}
            />
          </Route>
          <Route exact path="/profile">
            <Profile
              loadWeb3={loadWeb3}
              contract={contract}
              babiesContract={babiesContract}
              metamask={metamask}
              isWalletConnected={isWalletConnected}
              isWhitelisted={isWhitelisted}
              alertState={alertState}
              setAlertState={setAlertState}
              account={account}
              fatBananasContract={fatBananasContract}
              ladiesContract={ladiesContract}
            />
          </Route>
          <Route exact path="/lookup">
            <Lookup
              loadWeb3={loadWeb3}
              babiesContract={babiesContract}
              alertState={alertState}
              setAlertState={setAlertState}
            />
          </Route>
          <Route exact path="/lookup/fatbananas">
            <LookupFAB
              loadWeb3={loadWeb3}
              fatBananasContract={fatBananasContract}
              alertState={alertState}
              setAlertState={setAlertState}
            />
          </Route>
          <Route exact path="/ladiesclub">
            <Ladies
              account={account}
              loadWeb3={loadWeb3}
              fatBananasContract={fatBananasContract}
              ladiesContract={ladiesContract}
              alertState={alertState}
              setAlertState={setAlertState}
              metamask={metamask}
              isWalletConnected={isWalletConnected}
              setCount={setCount}
              count={count}
              isPaused={isPaused}
              isSoldOut={isSoldOut}
              exceedLimit={exceedLimit}
              isPublic={isPublic}
              isMinting={isMinting}
              mintLady={mintLady}
              isFatBananasMinting={isFatBananasMinting}
              cost={cost}
              fatBananasCost={fatBananasCost}
              buyWithFatBananas={buyWithFatBananas}
            />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
