import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Grid from "@mui/material/Grid";
import placeholder from "../assets/black.png";
import AddIcon from "@material-ui/icons/Add";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Breeding = (props) => {
  const [breeding, setBreeding] = useState(false);
  const [father, setFather] = useState(null);
  const [mother, setMother] = useState(null);
  const [type, setType] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [tokenURIs, setTokenURIs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // const [breedingTokens, setBreedingTokens] = useState([]);
  const [value, setValue] = useState(0); // integer state
  const [sliceNumber, setSliceNumber] = useState(3);

  useEffect(() => {
    let width = window.screen.width;
    if (width < 650) {
      setSliceNumber(1);
    } else {
      setSliceNumber(3);
    }
  });
  useEffect(() => {}, [value]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const breed = async () => {
    if (!father || !mother) {
      props.setAlertState({
        open: true,
        message: "Please select parents",
        severity: "error",
      });
    } else {
      await props.loadWeb3();

      setBreeding(true);

      props.babiesContract.methods
        .breed(
          father.edition,
          "0xF3114DD5c5b50a573E66596563D15A630ED359b4",
          mother.edition,
          "0xF3114DD5c5b50a573E66596563D15A630ED359b4"
        )
        .send({ from: props.account })
        .once("receipt", (receipt) => {
          props.setAlertState({
            open: true,
            message: "Success! A baby is on the way",
            severity: "success",
          });
          setBreeding(false);
          window.location.replace("/profile");
        })
        .catch((e) => {
          console.log(e);
          // props.setAlertState({
          //   open: true,
          //   message: "Something went wrong",
          //   severity: "error",
          // });
          setBreeding(false);
        });
    }
  };

  useEffect(() => {
    async function fetch() {
      if (props.account && props.contract) {
        props.contract.methods
          .walletOfOwner(props.account)
          .call()
          .then((tokens) => {
            tokens.forEach((token) => {
              props.contract.methods
                .tokenURI(token)
                .call()
                .then((tokenURI) => {
                  if (tokenURIs.indexOf(tokenURI) === -1) {
                    tokenURI = tokenURI.replace(
                      "https://gateway.pinata.cloud/ipfs",
                      "https://ipfs.io/ipfs"
                    );
                    setTokenURIs((tokenURIs) =>
                      [...tokenURIs, tokenURI].sort(
                        (a, b) =>
                          a.split("/").pop().replace(".json", "") -
                          b.split("/").pop().replace(".json", "")
                      )
                    );
                  }
                });
            });
          });
      }
    }
    fetch();
  }, [props.contract]);

  const getMetadata = () => {
    const fetchAll = async (urls) => {
      const res = await Promise.all(urls.map((u) => fetch(u)));
      const jsons = await Promise.all(res.map((r) => r.json())).catch(function (
        err
      ) {
        console.log(err.message); // some coding error in handling happened
      });

      breedingData(jsons);
    };

    // const slice = page * 3 - 3;
    // const slice_end = slice + 3;

    const slice = page * sliceNumber - sliceNumber;
    const slice_end = slice + sliceNumber;

    if (tokenURIs) {
      fetchAll(tokenURIs.slice(slice, slice_end));
    } else {
      setLoading(false);
    }
  };

  const breedingData = (jsons) => {
    const new_jsons = JSON.parse(JSON.stringify(jsons));
    let temp_array = [];

    new_jsons.forEach((j, index) => {
      let temp_object = {};
      props.babiesContract.methods
        .apeReadyToBreed(
          "0xF3114DD5c5b50a573E66596563D15A630ED359b4",
          j.edition
        )
        .call()
        .then((isReady) => {
          temp_object.readyToBreed = isReady;
          temp_object.attributes = j.attributes;
          temp_object.edition = j.edition;
          temp_object.name = j.name;
          temp_object.image = j.image;

          if (isReady == true) {
            temp_object.breedDate = null;

            if (temp_array.indexOf(temp_object) === -1) {
              temp_array.push(temp_object);
            }

            if (temp_array.length === jsons.length) {
              temp_array = temp_array.sort((a, b) =>
                a.edition > b.edition ? 1 : -1
              );
              setTokens(temp_array);
              setLoading(false);
              setValue((value) => value + 1);
            }
          } else {
            props.babiesContract.methods
              .apeNextBreedingDate(
                "0xF3114DD5c5b50a573E66596563D15A630ED359b4",
                j.edition
              )
              .call()
              .then((date) => {
                temp_object.breedDate = date;
                if (temp_array.indexOf(temp_object) === -1) {
                  temp_array.push(temp_object);
                }
                if (temp_array.length === jsons.length) {
                  temp_array = temp_array.sort((a, b) =>
                    a.edition > b.edition ? 1 : -1
                  );
                  setTokens(temp_array);
                  setLoading(false);
                  setValue((value) => value + 1);
                }
              });
          }
        });
    });
    return temp_array;
  };

  useEffect(async () => {
    setLoading(true);
    await getMetadata();
  }, [page]);

  const selectParent = (type) => {
    if (!props.metamask) {
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
        "_blank"
      );
    } else if (!props.isWalletConnected) {
      window.ethereum.enable();
    } else {
      getMetadata();
      setType(type);
      setToggle(true);
    }
  };

  const setParent = (type, token) => {
    const stringify_father = JSON.stringify(father);
    const stringify_mother = JSON.stringify(mother);
    const stringify_token = JSON.stringify(token);

    if (father == null && mother == null) {
      type == "father" ? setFather(token) : setMother(token);
    } else if (
      type == "father" &&
      stringify_mother != stringify_token &&
      stringify_father != stringify_mother
    ) {
      setFather(token);
    } else if (
      type == "mother" &&
      stringify_father != stringify_token &&
      stringify_mother != stringify_father
    ) {
      setMother(token);
    } else {
      props.setAlertState({
        open: true,
        message: "Can't fuck myself, select a different ape",
        severity: "error",
      });
    }
    setToggle(false);
  };

  const AsyncImage = (props) => {
    const [loadedSrc, setLoadedSrc] = React.useState(null);
    React.useEffect(() => {
      setLoadedSrc(null);
      if (props.src) {
        const handleLoad = () => {
          setLoadedSrc(props.src);
        };
        const image = new Image();
        image.addEventListener("load", handleLoad);
        image.src = props.src;
        return () => {
          image.removeEventListener("load", handleLoad);
        };
      }
    }, [props.src]);
    if (loadedSrc === props.src) {
      return <img {...props} />;
      // return <CircularProgress style={{ color: "white" }} />
    }

    return <img src={placeholder} className="breeding-img" />;

    // return <CircularProgress style={{ color: "white" }} />;
  };

  const popUp = () => {
    return (
      <div className="popup-overlay">
        <div className="popup-container">
          <div className="popup-title-container">
            <h3 className="popup-title">Select an Ape to breed</h3>
            <span className="close-popup" onClick={() => setToggle(false)}>
              x
            </span>
          </div>
          <Grid container>
            {tokens?.length > 0 && loading == false ? (
              tokens.map((token, i) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  className="team-item-container"
                  key={i}
                >
                  <AsyncImage
                    className={
                      token.readyToBreed == true
                        ? "breeding-img"
                        : "breeding-img breeding-not-ready"
                    }
                    src={token.image.replace("ipfs:", "https://ipfs.io/ipfs")}
                    alt="fatApe"
                    onClick={() => {
                      setParent(type, token);
                    }}
                  />

                  <div className="team-desc">
                    <h3>{token.name}</h3>
                    {token.readyToBreed == false ? (
                      <div className="breeding-time-container">
                        <p>Ready to breed:</p>
                        <p>
                          {new Date(
                            parseInt(token.breedDate * 1000)
                          ).toLocaleString()}
                        </p>
                      </div>
                    ) : null}
                    {/* <p>Senior Programmer</p> */}
                  </div>
                </Grid>
              ))
            ) : loading && tokenURIs.length > 0 ? (
              <div className="loading-container">
                <h3>Loading...</h3>
              </div>
            ) : (
              <div style={{ textAlign: "center", width: "100%" }}>
                <p>Looks like you don't own any apes</p>
              </div>
            )}
          </Grid>
          {tokenURIs.length > sliceNumber ? (
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(tokenURIs.length / sliceNumber)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Stack>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <Container className="container container-margin-top">
      {toggle ? popUp() : null}
      <h2 style={{ textAlign: "center" }}>Breed Apes</h2>
      <div className="breeding-subtext">
        <p>
          Once the mating is done, you will automatically see your unrevealed
          Baby Fat Ape appear in your wallet, but it will take <b>4 weeks</b>{" "}
          for the babies to reveal. There is also a <b>8 weeks cooldown</b> for
          the parents, which means that the Fat Apes can only breed once every 8
          weeks.
        </p>
        <p>
          Breeding rare Fat Apes increases your odds of getting a{" "}
          <b>rare baby</b> as well.
        </p>
        <p>
          <b>
            Before buying any ape to breed, you should always lookup if he's
            ready to breed.
          </b>
        </p>
        <div
          onClick={() => window.location.replace("/lookup")}
          className="top40"
        >
          <a className="connect-button border-btn ">Lookup an ape</a>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} md={6} lg={6} className="team-item-container">
          <div className="team-desc">
            <h3>Padre</h3>
            {/* <p>Senior Programmer</p> */}
          </div>
          {!father ? (
            <div
              onClick={() => selectParent("father")}
              className="breeding-img-container"
            >
              <img src={placeholder} className="breeding-img" />
              <AddIcon className="breeding-add" />
            </div>
          ) : (
            <>
              <div
                onClick={() => selectParent("father")}
                className="breeding-img-container"
              >
                <img
                  className="breeding-img"
                  src={father.image.replace("ipfs:", "https://ipfs.io/ipfs")}
                  alt="fatApe-Father"
                />
              </div>
              <h3>{father.name}</h3>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} className="team-item-container">
          <div className="team-desc">
            <h3>Dame</h3>
            {/* <p>Marketing Genius</p> */}
          </div>
          {!mother ? (
            <div
              onClick={() => selectParent("mother")}
              className="breeding-img-container"
            >
              <img src={placeholder} className="breeding-img" />
              <AddIcon className="breeding-add" />
            </div>
          ) : (
            <>
              <div
                onClick={() => selectParent("mother")}
                className="breeding-img-container"
              >
                <img
                  className="breeding-img"
                  src={mother.image.replace("ipfs:", "https://ipfs.io/ipfs")}
                  alt="fatApe-mother"
                />
              </div>
              <h3>{mother.name}</h3>
            </>
          )}
        </Grid>
      </Grid>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ textAlign: "center" }}>
          {!props.metamask ? (
            <div
              className="connect-button"
              onClick={() =>
                window.open(
                  "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                  "_blank"
                )
              }
            >
              Install Metamask
            </div>
          ) : !props.isWalletConnected ? (
            <div
              className="connect-button"
              onClick={() => window.ethereum.enable()}
            >
              Connect Wallet
            </div>
          ) : (
            <div className="breed-btn-container">
              <Button
                // disabled={!father || !mother}
                onClick={() => breed()}
                variant="contained"
                className={
                  !father || !mother
                    ? "connect-button breed-btn breed-disable"
                    : "connect-button breed-btn"
                }
              >
                {breeding ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Breed"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        open={props.alertState.open}
        autoHideDuration={6000}
        onClose={() =>
          props.setAlertState({ ...props.alertState, open: false })
        }
        className="alert"
      >
        <Alert
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          severity={props.alertState.severity}
          className="alert"
        >
          <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Breeding;
