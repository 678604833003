import React, { useEffect, useState } from "react";
import { CircularProgress, Button } from "@material-ui/core";

const Modal = (props) => {
  const [unclaimed, setUnclaimed] = useState();
  const [isClaiming, setIsClaiming] = useState(false);

  useEffect(() => {
    if (props.modal.token?.edition && props.account) {
      props.fatBananasContract.methods
        .rewardBalance(props.modal.token.edition)
        .call()
        .then((balance) => {
          setUnclaimed(balance);
        });
    }
  }, [props.modal.token]);

  const claim = () => {
    setIsClaiming(true);

    props.fatBananasContract.methods
      .claim(props.modal.token.edition)
      .send({ from: props.account })
      .once("receipt", (receipt) => {
        setIsClaiming(false);

        props.setAlertState({
          open: true,
          message: "Successfully claimed Fat Bananas",
          severity: "success",
        });

        props.loadBlockchainData();
      })
      .catch((e) => {
        setIsClaiming(false);
      });
  };

  return (
    <div>
      {props.modal.open ? (
        <div className="modal-background">
          <div>
            <div className="close-popup" onClick={() => props.setModal(false)}>
              <span>x</span>
            </div>
            <img
              className="modal-img"
              src={props.modal.token.image.replace(
                "ipfs://",
                "https://ipfs.io/ipfs/"
              )}
            />
            <h3 id="transition-modal-title" variant="h6" component="h2">
              {props.modal.token?.name}
            </h3>
            <div>
              <p>
                Balance: {(unclaimed / 1000000000000000000).toFixed(2)}
                <span style={{ color: "rgba(255,255,255,0.75)" }}>FAB</span>
              </p>
              <Button
                // disabled={!father || !mother}
                onClick={() => claim()}
                variant="contained"
                className="connect-button modal-btn"
                // className={
                //   !father || !mother
                //     ? "connect-button breed-btn breed-disable"
                //     : "connect-button breed-btn"
                // }
              >
                {isClaiming ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "Claim"
                )}
              </Button>
              {/* {isClaiming ? (
                <div className="claiming-loading-container">
                  <CircularProgress style={{ color: "#3d3d3d" }} />
                </div>
              ) : (
                <button>Claim</button>
              )} */}
              {/* <div style={{ marginTop: "40px" }}>
                <Button
                  text="mutate"
                  newWindow={0}
                  location={`/mutate/${props.modal.token.edition}`}
                />
              </div> */}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Modal;
