import React, { useEffect, useState } from "react";
import { Snackbar, CircularProgress } from "@material-ui/core";
import Container from "@mui/material/Container";
import Footer from "../components/Footer";
import Alert from "@material-ui/lab/Alert";
import MintButton from "../components/MintLadiesButton";

const Ladies = (props) => {
  //   const buyWithFatBananas = () => {
  //     setFatBananasIsMinting(true);
  //   };
  const Content = () => {
    return (
      <div>
        <MintButton
          metamask={props.metamask}
          isWalletConnected={props.isWalletConnected}
          mintApe={props.mintLady}
          startDate={props.startDate}
          isActive={props.isActive}
          setIsActive={props.setIsActive}
          setCount={props.setCount}
          count={props.count}
          isPaused={props.isPaused}
          isSoldOut={props.isSoldOut}
          isWhitelisted={props.isWhitelisted}
          exceedLimit={props.exceedLimit}
          isPublic={props.isPublic}
          alertState={props.alertState}
          setAlertState={props.setAlertState}
          isMinting={props.isMinting}
          cost={props.cost}
          fatBananasCost={props.fatBananasCost}
          isFatBananasMinting={props.isFatBananasMinting}
          buyWithFatBananas={props.buyWithFatBananas}
        />
      </div>
    );
  };

  return (
    <>
      <Container
        className="container container-margin-top"
        style={{ minHeight: "100vh" }}
      >
        <h2 style={{ textAlign: "center" }}>Ladies Club</h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            {!props.metamask ? (
              <div
                className="connect-button border-btn"
                onClick={() =>
                  window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                  )
                }
              >
                Install Metamask
              </div>
            ) : !props.isWalletConnected ? (
              <div
                className="connect-button border-btn"
                onClick={() => window.ethereum.enable()}
              >
                Connect Wallet
              </div>
            ) : (
              <Content />
            )}
          </div>
        </div>
        <Snackbar
          open={props.alertState.open}
          autoHideDuration={6000}
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          className="alert"
        >
          <Alert
            onClose={() =>
              props.setAlertState({ ...props.alertState, open: false })
            }
            severity={props.alertState.severity}
            className="alert"
          >
            <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
          </Alert>
        </Snackbar>
      </Container>
      <Footer />
    </>
  );
};

export default Ladies;
