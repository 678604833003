import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";

const Terms = () => {
  return (
    <Container className="container">
      <div style={{ paddingTop: "10%" }}>
        <h1>TERMS</h1>
        <p>
          Fat Ape Club is a collection a collection of digital artworks running
          on the Ethereum network via non fungible tokens(NFTs). Fatapeclub.io
          is only a visual interface to allow users to acquire the digital
          artworks. Users are responsible for the safety and management of their
          own Ethereum wallet and validating all transactions and contracts
          related and generated by fatapeclub.io before approval. Also be aware
          that, since our Fate Ape Club smart contract runs on the Ethereum
          network, it is not possible to undo, reverse or restore any
          transactions. Fatapeclub.io and its connected services are provided
          “as is” and “as available” without warranty of any kind. By using
          fatapeclub.io you are accepting sole responsibility for any and all
          transactions involving Fat Ape Club digital artworks.
        </p>

        <h1>OWNERSHIP</h1>
        <p>
          i. The user that is buying and minting our Fat Ape Club artwork is the
          owner of the Non Fungible Token (NFT) crated by validating the
          transaction. Each Fat Ape is an NFT on the Ethereum blockchain. When
          you purchase an NFT, you own all the rights to the underlying Fat Ape.
          Ownership of the NFT is mediated entirely and only by the Smart
          Contract and the Ethereum Network which means that, at no point, may
          we seize, freeze, or otherwise modify the ownership of any Fat Ape.
          ii. Personal Use. Subject to your continued compliance with these
          Terms, fatapeclub.io grants you a worldwide, royalty-free license to
          use, copy, and display the purchased Fat Ape(s), along with any
          extensions that you choose to create or use, solely for the following
          purposes: (i) for your own personal, non-commercial use; (ii) as part
          of a marketplace that permits the purchase and sale of your Fat
          Ape/NFT, provided that the marketplace cryptographically verifies each
          Fat Ape owner’s rights to display the Fat Ape and ensures that only
          the actual owner can display the Fat Ape; or (iii) as part of a third
          party website or application that permits the inclusion, involvement,
          or participation of your Fat Ape, provided that the
          website/application cryptographically verifies each Fat Ape owner’s
          rights to display the Fat Ape and ensures that only the actual owner
          can display the Fat Ape, and provided that the Fat Ape is no longer
          visible once the owner of the Fat Ape leaves the website/application.
          iii. Commercial Use. Subject to your continued compliance with these
          Terms, fatapeclub.io grants you an unlimited, worldwide license to
          use, copy, and display the purchased Fat Ape for the purpose of
          creating derivative works based upon the Fat Ape (“Commercial Use”).
          Examples of such Commercial Use would be the use of the Fat Ape to
          produce and sell merchandise products (T-Shirts etc.) displaying
          copies of the Fat Ape. For the sake of clarity, nothing in this
          Section will be deemed to restrict you from (i) owning or operating a
          marketplace that permits the use and sale of Fat Ape generally,
          provided that the marketplace cryptographically verifies each Fat Ape
          owner’s rights to display the Fat Ape and ensures that only the actual
          owner can display the Fat Ape; (ii) owning or operating a third party
          website or application that permits the inclusion, involvement, or
          participation of Fat Apes generally, provided that the third party
          website or application cryptographically verifies each Fat Ape owner’s
          rights to display the Fat Ape and ensures that only the actual owner
          can display the Fat Ape, and provided that the Fat Ape is no longer
          visible once the owner of the Purchased Fat Ape leaves the
          website/application; or (iii) earning revenue from any of the
          foregoing.
        </p>

        <h1>LAMBO GIVEAWAY</h1>
        <p>
          When you mint a Fat Ape, you automatically get a chance to win a 2020
          Lamborghini Huracán.The winner can choose between a 2020 Lamborghini
          Huracán at a maximum listed price of $250 000 US dollars or $250 000
          US dollars worth of ETH. The winner will be decided by a draw that the
          Fat Ape Club Team will make 48 hours after the collection is sold out.
          The winner will also get a unique 1/1 Fat Ape NFT. One minted Fat Ape
          is equal to one chance to win the draw. The draw will only happen if
          the collection of 9999 Fat Apes is sold out. If the totally ff the
          9999 Fat Apes are not sold entirely, there won’t be a draw and nobody
          will win the prize. Depending on the region of the winner and other
          factors, we reserve the right to pay the raffle prize in ETH and not
          buy and send a 2020 Lamborghini Huracán to the winner. The winner of
          the draw will need to give the Fat Ape Club team his Metamask wallet
          address in order for us to make the payment.
        </p>
      </div>
      <p style={{ fontSize: "15px" }}>
        To enter our giveaways, you can also send an email to
        influencers@fatapeclub.io stating the giveaway that you want to enter
        and your Discord id.
      </p>
    </Container>
  );
};

export default Terms;
