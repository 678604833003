import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Snackbar, Button, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Grid from "@mui/material/Grid";
import placeholder from "../assets/black.png";
// import AddIcon from "@material-ui/icons/Add";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Modal from "../components/Modal";
import Footer from "../components/Footer";

const Breeding = (props) => {
  const [tokens, setTokens] = useState([]);
  const [allTokens, setAllTokens] = useState([]);
  const [tokenURIs, setTokenURIs] = useState([]);
  const [babiesTokens, setBabiesTokens] = useState([]);
  const [BabiesTokenURIs, setBabiesTokenURIs] = useState([]);
  const [ladiesTokens, setLadiesTokens] = useState([]);
  const [LadiesTokenURIs, setLadiesTokenURIs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [babiesLoading, setBabiesLoading] = useState(false);
  const [ladiesLoading, setLadiesLoading] = useState(false);

  let [slice, setSlice] = useState(3);
  let [babySlice, setBabySlice] = useState(3);
  let [ladySlice, setLadySlice] = useState(3);

  const [modal, setModal] = useState({
    open: false,
    token: {},
  });
  const [isClaiming, setIsClaiming] = useState(false);
  const [unclaimed, setUnclaimed] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    async function fetch() {
      if (props.account && props.contract) {
        props.contract.methods
          .walletOfOwner(props.account)
          .call()
          .then((tokens) => {
            setAllTokens(tokens);

            tokens.forEach((token) => {
              props.contract.methods
                .tokenURI(token)
                .call()
                .then((tokenURI) => {
                  if (tokenURIs.indexOf(tokenURI) === -1) {
                    tokenURI = tokenURI.replace(
                      "https://gateway.pinata.cloud/ipfs",
                      "https://ipfs.io/ipfs"
                    );

                    setTokenURIs((tokenURIs) =>
                      [...tokenURIs, tokenURI].sort(
                        (a, b) =>
                          a.split("/").pop().replace(".json", "") -
                          b.split("/").pop().replace(".json", "")
                      )
                    );
                  }
                });
            });
          });
      }
    }

    fetch();
  }, [props.contract]);

  useEffect(() => {
    async function fetchBabies() {
      if (props.account && props.babiesContract) {
        props.babiesContract.methods
          .walletOfOwner(props.account)
          .call()
          .then((tokens) => {
            tokens.forEach((token) => {
              if (babiesTokens.indexOf(token) === -1) {
                let babyTokenURI = `https://api.fatapeclub.io/babyApes/metadata/${token}`;
                setBabiesTokenURIs((tokenURIs) =>
                  [...tokenURIs, babyTokenURI].sort(
                    (a, b) =>
                      a.split("/").pop().replace(".json", "") -
                      b.split("/").pop().replace(".json", "")
                  )
                );
              }
            });
          });
      }
    }

    fetchBabies();
  }, [props.babiesContract]);

  useEffect(() => {
    async function fetchLadies() {
      if (props.account && props.ladiesContract) {
        props.ladiesContract.methods
          .walletOfOwner(props.account)
          .call()
          .then((tokens) => {
            tokens.forEach((token) => {
              props.ladiesContract.methods
                .tokenURI(token)
                .call()
                .then((tokenURI) => {
                  if (LadiesTokenURIs.indexOf(tokenURI) === -1) {
                    tokenURI = tokenURI.replace(
                      "ipfs://",
                      "https://ipfs.io/ipfs/"
                    );

                    setLadiesTokenURIs((tokenURIs) =>
                      [...tokenURIs, tokenURI].sort(
                        (a, b) =>
                          a.split("/").pop().replace(".json", "") -
                          b.split("/").pop().replace(".json", "")
                      )
                    );
                  }
                });
            });
          });
      }
    }

    fetchLadies();
  }, [props.ladiesContract]);

  const getMetadata = async () => {
    const fetchAll = async (urls) => {
      const res = await Promise.all(urls.map((u) => fetch(u)));
      const jsons = await Promise.all(res.map((r) => r.json())).catch(function (
        err
      ) {
        console.log(err.message); // some coding error in handling happened
      });
      setTokens(jsons);
      setLoading(false);
    };

    // const slice = page * 3 - 3;
    // const slice_end = slice + 3;

    fetchAll(tokenURIs.slice(0, slice));
  };

  const getBabiesMetadata = async () => {
    const fetchAll = async (urls) => {
      const res = await Promise.all(urls.map((u) => fetch(u)));
      const jsons = await Promise.all(res.map((r) => r.json())).catch(function (
        err
      ) {
        console.log(err.message); // some coding error in handling happened
      });
      setBabiesTokens(jsons);
      setBabiesLoading(false);
    };

    fetchAll(BabiesTokenURIs.slice(0, babySlice));
  };

  const getLadiesMetadata = async () => {
    const fetchAll = async (urls) => {
      const res = await Promise.all(urls.map((u) => fetch(u)));
      const jsons = await Promise.all(res.map((r) => r.json())).catch(function (
        err
      ) {
        console.log(err.message); // some coding error in handling happened
      });
      setLadiesTokens(jsons);
      setLadiesLoading(false);
    };

    fetchAll(LadiesTokenURIs.slice(0, ladySlice));
  };

  useEffect(() => {
    getMetadata();
  }, [tokenURIs, slice]);

  useEffect(() => {
    getBabiesMetadata();
  }, [BabiesTokenURIs, babySlice]);

  useEffect(() => {
    getLadiesMetadata();
  }, [LadiesTokenURIs, ladySlice]);

  const AsyncImage = (props) => {
    const [loadedSrc, setLoadedSrc] = React.useState(null);
    React.useEffect(() => {
      setLoadedSrc(null);
      if (props.src) {
        const handleLoad = () => {
          setLoadedSrc(props.src);
        };
        const image = new Image();
        image.addEventListener("load", handleLoad);
        image.src = props.src;
        return () => {
          image.removeEventListener("load", handleLoad);
        };
      }
    }, [props.src]);
    if (loadedSrc === props.src) {
      return <img {...props} />;
      // return <CircularProgress style={{ color: "white" }} />
    }

    return <img src={placeholder} className="breeding-img" />;

    // return <CircularProgress style={{ color: "white" }} />;
  };

  const claim = () => {
    setIsClaiming(true);

    if (unclaimed > 0) {
      props.fatBananasContract.methods
        .batchClaim(allTokens)
        .send({ from: props.account })
        .once("receipt", (receipt) => {
          setIsClaiming(false);

          props.setAlertState({
            open: true,
            message: "Successfully claimed Fat Bananas",
            severity: "success",
          });
          props.loadBlockchainData();
        })
        .catch((e) => {
          setIsClaiming(false);
        });
    } else {
      setIsClaiming(false);
      props.setAlertState({
        open: true,
        message: "No Fat Bananas left",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    let totalBalance = 0;

    function rewards() {
      if (props.account && props.fatBananasContract && allTokens.length > 0) {
        allTokens.forEach((token, i) => {
          props.fatBananasContract.methods
            .rewardBalance(token)
            .call()
            .then((balance) => {
              totalBalance = totalBalance + parseInt(balance);
              setUnclaimed(totalBalance);
            });
        });

        props.fatBananasContract.methods
          .balanceOf(props.account)
          .call()
          .then((balance) => {
            setBalance(balance);
          });
      }
    }

    rewards();
  }, [tokens, props.fatBananasContract]);

  const Content = () => {
    return (
      <>
        <Modal
          modal={modal}
          setModal={setModal}
          fatBananasContract={props.fatBananasContract}
          contract={props.contract}
          account={props.account}
          setAlertState={props.setAlertState}
          loadBlockchainData={props.loadBlockchainData}
        />
        <div className="collection-container">
          <h3 className="collection-title">
            {(balance / 1000000000000000000).toFixed(2)}{" "}
            <span style={{ color: "rgba(255,255,255,0.75)" }}>
              (FAT BANANAS)
            </span>
          </h3>
          <Button
            onClick={() => claim()}
            variant="contained"
            className="connect-button modal-btn"
          >
            {isClaiming ? (
              <CircularProgress style={{ color: "white" }} />
            ) : (
              `Claim ${(unclaimed / 1000000000000000000).toFixed(2)}`
            )}
          </Button>
          <Button
            onClick={() => window.location.replace("/lookup/fatbananas")}
            variant="contained"
            style={{ display: "block", margin: "10px auto" }}
            className="connect-button modal-btn"
          >
            Lookup an ape
          </Button>
        </div>

        <div className="collection-container">
          <h3 className="collection-title">
            Fat Ape Club{" "}
            <span style={{ color: "rgba(255,255,255,0.75)" }}>(FAPE)</span>
          </h3>

          <Grid container>
            {tokens?.length > 0 ? (
              tokens.map((token, i) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="team-item-container"
                  key={i}
                  onClick={() => setModal({ open: true, token: token })}
                >
                  <AsyncImage
                    className="profile-img pointer"
                    src={token.image?.replace("ipfs:", "https://ipfs.io/ipfs")}
                    alt="fatApe"
                  />

                  <div className="team-desc">
                    <h3>{token.name}</h3>
                  </div>
                </Grid>
              ))
            ) : loading ? (
              <div style={{ textAlign: "center", width: "100%" }}>
                <h3 className="loading">Loading...</h3>
              </div>
            ) : (
              <div style={{ textAlign: "center", width: "100%" }}>
                <p>Looks like you don't own any ape</p>
              </div>
            )}
          </Grid>
          {slice >= tokenURIs.length ? null : (
            <div onClick={() => setSlice(slice + 3)} className="loadmore">
              <h3 className="loadmore">Load more</h3>
            </div>
          )}
        </div>

        <div>
          <h3 className="collection-title">
            Fat Ape Babies Club{" "}
            <span style={{ color: "rgba(255,255,255,0.75)" }}>(FABC)</span>
          </h3>

          <Grid container>
            {babiesTokens?.length > 0 ? (
              babiesTokens.map((token, i) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="team-item-container"
                  key={i}
                >
                  <AsyncImage
                    className="profile-img"
                    src={token.image?.replace("ipfs:", "https://ipfs.io/ipfs")}
                    alt="fatApe"
                  />

                  <div className="team-desc">
                    <h3>{token.name}</h3>
                  </div>
                </Grid>
              ))
            ) : babiesLoading ||
              (BabiesTokenURIs.length > 0 && !babiesTokens) ? (
              <div style={{ textAlign: "center", width: "100%" }}>
                <h3 className="loading">Loading...</h3>
              </div>
            ) : (
              <div style={{ textAlign: "center", width: "100%" }}>
                <p>Looks like you don't own any baby ape</p>
              </div>
            )}
          </Grid>
          {babySlice >= BabiesTokenURIs.length ? null : (
            <div
              onClick={() => setBabySlice(babySlice + 3)}
              className="loadmore"
            >
              <h3 className="loadmore">Load more</h3>
            </div>
          )}
        </div>

        <div>
          <h3 className="collection-title">
            Fat Ape Ladies Club{" "}
            <span style={{ color: "rgba(255,255,255,0.75)" }}>(FALC)</span>
          </h3>

          <Grid container>
            {ladiesTokens?.length > 0 ? (
              ladiesTokens.map((token, i) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="team-item-container"
                  key={i}
                >
                  <AsyncImage
                    className="profile-img"
                    src={token.image?.replace("ipfs:", "https://ipfs.io/ipfs")}
                    alt="fatApe"
                  />

                  <div className="team-desc">
                    <h3>{token.name}</h3>
                  </div>
                </Grid>
              ))
            ) : ladiesLoading ||
              (LadiesTokenURIs.length > 0 && !ladiesTokens) ? (
              <div style={{ textAlign: "center", width: "100%" }}>
                <h3 className="loading">Loading...</h3>
              </div>
            ) : (
              <div style={{ textAlign: "center", width: "100%" }}>
                <p>Looks like you don't own any fat ladies</p>
              </div>
            )}
          </Grid>
          {ladySlice >= LadiesTokenURIs.length ? null : (
            <div
              onClick={() => setLadySlice(ladySlice + 3)}
              className="loadmore"
            >
              <h3 className="loadmore">Load more</h3>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Container
        className="container container-margin-top"
        style={{ minHeight: "100vh" }}
      >
        <h2 style={{ textAlign: "center" }}>Profile</h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ textAlign: "center" }}>
            {!props.metamask ? (
              <div
                className="connect-button border-btn"
                onClick={() =>
                  window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                  )
                }
              >
                Install Metamask
              </div>
            ) : !props.isWalletConnected ? (
              <div
                className="connect-button border-btn"
                onClick={() => window.ethereum.enable()}
              >
                Connect Wallet
              </div>
            ) : (
              <Content />
            )}
          </div>
        </div>
        <Snackbar
          open={props.alertState.open}
          autoHideDuration={6000}
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          className="alert"
        >
          <Alert
            onClose={() =>
              props.setAlertState({ ...props.alertState, open: false })
            }
            severity={props.alertState.severity}
            className="alert"
          >
            <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
          </Alert>
        </Snackbar>
      </Container>
      <Footer />
    </>
  );
};

export default Breeding;
