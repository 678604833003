import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import Footer from "../components/Footer";

const Lookup = (props) => {
  const [tokenId, setTokenId] = useState(null);
  const [balance, setBalance] = useState(null);
  console.log(balance);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length < 5 && value[0] != 0) {
      setTokenId(value);
    }
  };

  const getBalance = () => {
    if (tokenId < 1 || tokenId > 9999) {
      setBalance(null);
      props.setAlertState({
        open: true,
        message: "Please enter a value between 1 and 9999",
        severity: "error",
      });
    } else if (props.fatBananasContract) {
      props.fatBananasContract.methods
        .rewardBalance(tokenId)
        .call()
        .then((rewardBalance) => {
          setBalance(parseInt(rewardBalance) / 1000000000000000000);
        });
    } else if (!props.fatBananasContract) {
      props.setAlertState({
        open: true,
        message: `Looks like metamask is not installed`,
        severity: "error",
      });
    }
  };

  return (
    <>
      <Container className="verify-container">
        <div>
          <h2 style={{ textAlign: "center" }}>Lookup FAB</h2>

          <div className="lookup-input-container">
            <TextField
              id="outlined-basic"
              label="Fat Ape ID"
              variant="outlined"
              className="lookup-input"
              //   inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              value={tokenId}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div onClick={() => getBalance()} className="top40">
            <a className="connect-button border-btn ">Lookup an ape</a>
          </div>
          {balance && (
            <div>
              <p>{balance.toFixed(2)} $FAB</p>
            </div>
          )}
        </div>
        <Snackbar
          open={props.alertState.open}
          autoHideDuration={6000}
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          className="alert"
        >
          <Alert
            onClose={() =>
              props.setAlertState({ ...props.alertState, open: false })
            }
            severity={props.alertState.severity}
            className="alert"
          >
            <h3 style={{ fontSize: "15px" }}>{props.alertState.message}</h3>
          </Alert>
        </Snackbar>
      </Container>
      <Footer />
    </>
  );
};

export default Lookup;
